const ApiUrl = 'https://brilho.goldpos.com.co/apiGold/';
// const ApiUrl = 'http://localhost:53030//apiGold/';

const ApiRaiz = 'https://brilho.goldpos.com.co/';


const BackgroundColor = '#4175CB';


export { ApiUrl, BackgroundColor,ApiRaiz }
